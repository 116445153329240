import React from "react"
import Layout from "components/layout"
import SEO from "components/seo"
import BusinessPage from "pages/business"

export const Head = () => (
  <SEO
    title="經營理念"
    description="衣庫國際秉持商譽以及專業經營，款式多、庫存足、效率高、品質穩。迄今已與上千家業者合作，提供服裝市場多元、完整的選擇。現在，加入我們的行列，一起拼出新未來！"
    pathname="/business"
  />
)

export default () => (
  <Layout>
    <BusinessPage />
  </Layout>
)
